import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table, Drawer, Select, Typography, Tooltip, Empty, Dropdown, Menu, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import styles from './BaseGrid.module.scss';
import { filter, find, head, isEmpty, isEqual, isNil, last, omit, reduce, some, sortBy } from 'lodash';
import {
  getDoctorSpecial,
  getIsLoading,
  getSelectedRow,
  getSpecialRangesReasonsSearch,
  getSpecialRangesSearch,
  getTemplates,
  getTemplatesLoading,
} from '../../pace/pace-selectors';
import {
  assignTemplateSpecial,
  assignTemplateSpecialFailed,
  fetchAllTemplates,
  fetchDoctorsSpecial,
  fetchSelectedDoctorSpecial,
  fetchTemplates,
  setSelectedDoctor,
  setSelectedRow,
} from '../../pace/pace-actions';
import {
  formatDate,
  getDayIndex,
  getDontHaveTemplateForParticularHOO,
  getIsBetweenDates,
  getIsFirstDateOfMonth,
  getIsFitSchedule,
  getIsMonday,
  getIsSameMonth,
  getIsSameOrLaterThanToday,
  getIsToday,
  getMonthRangeValues,
  joinTime,
  substractOneDay,
} from '../../common/helpers/date';
import { showErrorNotification } from '@med-fe/util';
import { HourPatternCell } from './cell-components/HourPatternCell';
import { OfficeHeadCell } from './cell-components/OfficeHeadCell';
import styled from 'styled-components';
import useTemplateScroll from './hooks/use-template-scroll';
import { StyledCalendar, StyledMonthSelect, stylesMonthSelect, StyledSelect } from './components/styled/styled';
import { useDeepEffect } from '../../common/hooks/use-deep-effect';

const { Text } = Typography;

function SpecialRangesDetails({ search, loading, isVisible, doctorInfo, onClose }: any) {
  const dispatch = useDispatch();
  const { base, startDate } = search;
  const specialRangesSearch = useSelector(getSpecialRangesSearch);
  const specialRangesReasonsSearch = useSelector(getSpecialRangesReasonsSearch);
  const selectedRow = useSelector(getSelectedRow);
  const doctor = useSelector(getDoctorSpecial);
  const templatesLoading = useSelector(getTemplatesLoading);
  const templates = useSelector(getTemplates);
  const doctorLoading = useSelector(getIsLoading);
  const monthRanges = getMonthRangeValues();
  const [monthRangeValues, setMonthRangeValues]: any = useState([]);
  const [monthRangeValue, setMonthRangeValue]: any = useState(monthRanges[0]);
  const [isWholeWeek, setIsWholeWeek] = useState(new Array(6).fill(false));
  const [isODWholeWeek, setIsODWholeWeek] = useState(new Array(6).fill(false));

  const [selectedOptions, setSelectedOptions] = useState<null | {}>(null);
  const [initialDocTemplates, setInitialDocTemplates] = useState<any>(null);

  const [dayOfWeek, setDayOfWeek]: any = useState(null);
  const [selectedDate, setSelectedDate]: any = useState(null);
  const [isAssignDisabled, setIsAssignDisabled] = useState<boolean>(true);

  const { onTemplatesScroll, onSelectSearch, onSelectBlur } = useTemplateScroll({
    templatesLoading,
    office: doctor.office,
    dayOfWeek,
    setDayOfWeek,
  });

  useEffect(() => {
    setMonthRangeValues(monthRanges);
  }, []);

  const processTemplates = () => {
    const schedule = doctor?.schedule;
    const getSuggested = (dayIdx) => schedule?.[dayIdx]?.suggestedTemplate;
    const templates = {
      ...reduce(
        schedule,
        (acc, it, idx) => ({
          ...acc,
          ...(it?.specialRangeTemplateId
            ? {
                [it?.date]: {
                  key: String(it?.specialRangeTemplateId),
                  value: it?.specialRangeTemplateId,
                  children: it?.specialRangeTemplateName,
                  description: it?.specialRangeDescription ? it?.specialRangeDescription : it?.specialRangeTemplateName,
                },
              }
            : {
                ...(getIsSameMonth(monthRangeValue?.value ?? startDate, it?.date) &&
                getIsSameOrLaterThanToday(it?.date) &&
                getSuggested(idx)
                  ? {
                      [it?.date]: {
                        key: String(getSuggested(idx)?.id),
                        value: getSuggested(idx)?.id,
                        children: getSuggested(idx)?.templateName,
                        description: getSuggested(idx)?.templateName,
                        suggested: true,
                        cantFindTemplate: getSuggested(idx)?.cantFindTemplate,
                        removedNL: getSuggested(idx)?.removedNL,
                        specialRangesReasons: getSuggested(idx)?.specialRangesReasons,
                      },
                    }
                  : {}),
              }),
        }),
        {}
      ),
    };
    const areSuggestedTemplates: any = !isEmpty(
      Object.values(templates).filter((template: any) => template.suggested && !template.cantFindTemplate)
    );
    const noAssignedTemplates = doctor.weeks
      .flatMap((week) => week)
      .filter((elem) => getIsSameMonth(monthRangeValue?.value ?? startDate, elem.date))
      ?.every(({ specialRangeTemplateId }) => isNil(specialRangeTemplateId));

    setSelectedOptions(templates);
    if (noAssignedTemplates) {
      setInitialDocTemplates({});
      if (areSuggestedTemplates) {
        setIsAssignDisabled(false);
      }
    } else {
      setInitialDocTemplates(templates);
    }
    return templates;
  };

  useDeepEffect(() => {
    if (isNil(selectedOptions) && !isEmpty(doctor) && !isEmpty(doctor?.schedule)) {
      processTemplates();
      dispatch(fetchAllTemplates());
    }
  }, [doctor]);

  useEffect(() => {
    if (!isEmpty(doctor) && !isEmpty(doctor?.schedule) && !doctor.saved) {
      processTemplates();
    }
  }, [doctor.schedule]);

  useEffect(() => {
    dispatch(setSelectedDoctor({}));
    setSelectedOptions(null);
  }, [monthRangeValue]);

  useEffect(() => {
    if (!isNil(selectedRow)) {
      dispatch(
        fetchSelectedDoctorSpecial({
          id: doctor.employeeId || doctorInfo.employeeId,
          office: doctor.office || doctorInfo.office,
          base,
          startDate: monthRangeValue?.value ?? startDate,
          reason: doctor.reason || doctorInfo.reason,
        })
      );
    }
    setIsWholeWeek((prev) => prev?.map(() => false));
    setIsODWholeWeek((prev) => prev?.map(() => false));
  }, [monthRangeValue]);

  useEffect(() => {
    if (!isNil(dayOfWeek)) {
      dispatch(
        fetchTemplates({
          dayOfWeek,
          office: doctor?.office,
        })
      );
    }
  }, [dayOfWeek]);

  const isDisabled = (weekIdx) => {
    const week = doctor?.weeks?.[weekIdx];
    const firstDateOfWeek = head<any>(week)?.date;
    const lastDateOfWeek = last<any>(week)?.date;
    const todayDate = find(week, (o) => getIsToday(o?.date))?.date;
    const firstDateOfMonth = find(week, (o) => getIsFirstDateOfMonth(o?.date))?.date;
    const firstDateOfCurrentMonth = getIsSameMonth(firstDateOfMonth, monthRangeValue?.value)
      ? firstDateOfMonth
      : undefined;
    const lastDateOfCurrentMonth =
      !isNil(firstDateOfMonth) && !getIsSameMonth(firstDateOfMonth, monthRangeValue?.value)
        ? substractOneDay(firstDateOfMonth)
        : undefined;
    const startWeekDate = last(sortBy(filter([firstDateOfWeek, todayDate, firstDateOfCurrentMonth], Boolean)));
    const endWeekDate = head(sortBy(filter([lastDateOfWeek, lastDateOfCurrentMonth], Boolean)));
    const needToDisable =
      isNil(selectedOptions) ||
      isEmpty(selectedOptions) ||
      !Object.entries(selectedOptions)
        .filter(([date, _]) => getIsBetweenDates(startWeekDate, endWeekDate, date))
        .filter(([_, { children }]) => children)
        .map(([_, option]) => option)
        .some((_, idx, arr: any) => {
          const firstTemplateName = arr?.[0].children?.substring(arr[0].children?.indexOf(' ') + 1);

          return arr.every((elem) => {
            const templateName = elem.children?.substring(elem.children?.indexOf(' ') + 1);

            return isEqual(templateName, firstTemplateName);
          });
        });
    if (needToDisable) {
      if (isODWholeWeek[weekIdx]) {
        setIsODWholeWeek((prev) => prev?.map((val, idx) => (isEqual(weekIdx, idx) ? false : val)));
      }
      if (isWholeWeek[weekIdx]) {
        setIsWholeWeek((prev) => prev?.map((val, idx) => (isEqual(weekIdx, idx) ? false : val)));
      }
    }
    return needToDisable;
  };

  const allWeekWithoutHOO = () => {
    return doctor?.weeks?.[0].every((day) => !day.office);
  };

  const roundCoefficient = (minutes) => {
    return minutes?.split(' ')[0] > 15 ? 1 : 0;
  };

  // Function to determine the template based on conditions
  const determineTemplate = (hooHours, odHours, rangeZeroTemplateName, templateComputed) => {
    if (hooHours <= 6 || (odHours && odHours <= 6)) {
      return templateComputed?.includes('NL') ? templateComputed : templateComputed?.replace(' ', ' NL ');
    }
    if (hooHours > 6 && odHours > 6) {
      return templateComputed?.includes('NL') ? templateComputed?.replace(' NL ', ' ') : templateComputed;
    }
    if (!odHours && hooHours <= 6) {
      return rangeZeroTemplateName?.templateName || templateComputed?.replace(' ', ' NL ');
    }
    if (!odHours && hooHours > 6) {
      return templateComputed?.includes('NL') ? templateComputed?.replace(' NL ', ' ') : templateComputed;
    }
    if (hooHours > 6 && odHours <= 6) {
      return templateComputed?.includes('NL') ? templateComputed : templateComputed?.replace(' ', ' NL ');
    }
    return templateComputed;
  };

  const getTemplateNameByOptions = (options, weekIdx, idx, description, odScheduleSelected) => {
    let templateName = Object.values(options as object)
      .filter(({ children }) => children)
      .filter((option) => !isEmpty(option))?.[0]?.children;
    let templateEnd = templateName?.substring(templateName?.indexOf(' ') + 1);
    let weekElement = filter(
      filter(doctor?.weeks?.[weekIdx], (o) => getIsSameOrLaterThanToday(o?.date)),
      (o) => getIsSameMonth(monthRangeValue?.value, o?.date)
    )?.[idx];
    if ((weekElement?.office && !odScheduleSelected) || (weekElement?.personal && odScheduleSelected)) {
      let start = odScheduleSelected
        ? weekElement?.personal?.start?.split(':')[0]
        : weekElement?.office?.start.split(':')[0];
      let end = odScheduleSelected
        ? parseInt(weekElement?.personal?.end?.split(':')[0]) +
          roundCoefficient(weekElement?.personal?.end?.split(':')[1])
        : parseInt(weekElement?.office?.end.split(':')[0]) + roundCoefficient(weekElement?.office?.end.split(':')[1]);
      let templateComputed = start + '-' + end + ' ' + templateEnd;
      let foundedTemplate = templates?.allTemplates?.find((template) => template?.templateName === templateComputed);
      const dayHooHours = weekElement?.office?.duration;
      const dayOdScheduleHours = weekElement?.personal?.duration;
      let tempName = determineTemplate(dayHooHours, dayOdScheduleHours, weekElement?.templateName, templateComputed);
      let tempDetails = templates?.allTemplates?.find((template) => template.templateName === tempName);
      const optionDetials = tempDetails ? { ...foundedTemplate, ...tempDetails } : foundedTemplate;
      return optionDetials
        ? {
            children: optionDetials?.templateName,
            key: `${optionDetials?.id}`,
            value: optionDetials?.id,
            description: tempDetails
              ? optionDetials?.templateName
              : description
              ? description
              : optionDetials?.templateName,
          }
        : {};
    }
    return {};
  };

  const onSelectChoice = (templateId, option, weekIdx) => {
    const invertedFilteredOptions = filterOptionsByWeek(selectedOptions, weekIdx, true);

    if (!isNil(selectedDate)) {
      setSelectedOptions((prev) => {
        if (!isNil(option)) {
          return filterEmptyOptions(
            isWholeWeek[weekIdx] || isODWholeWeek[weekIdx]
              ? {
                  ...filter(
                    filter(doctor?.weeks?.[weekIdx], (o) => getIsSameOrLaterThanToday(o?.date)),
                    (o) => getIsSameMonth(monthRangeValue?.value, o?.date)
                  )?.reduce(
                    (acc, { date, specialRangeDescription }, idx) => ({
                      ...acc,
                      [date]: allWeekWithoutHOO()
                        ? option
                        : getTemplateNameByOptions(
                            { 0: option },
                            weekIdx,
                            idx,
                            specialRangeDescription,
                            isODWholeWeek[weekIdx]
                          ),
                    }),
                    {}
                  ),
                  ...invertedFilteredOptions,
                }
              : {
                  ...prev,
                  [selectedDate]: {
                    children: option.children,
                    key: option.key,
                    value: option.value,
                    description: option.children,
                    schedule: option.schedule,
                  },
                }
          );
        } else {
          if (isWholeWeek[weekIdx]) {
            setIsWholeWeek((prev) => prev?.map((val, idx) => (isEqual(weekIdx, idx) ? false : val)));
          } else if (isODWholeWeek[weekIdx]) {
            setIsODWholeWeek((prev) => prev?.map((val, idx) => (isEqual(weekIdx, idx) ? false : val)));
          }

          return filterEmptyOptions(
            isWholeWeek[weekIdx] || isODWholeWeek[weekIdx]
              ? { ...invertedFilteredOptions }
              : { ...omit(prev, selectedDate) }
          );
        }
      });
      setIsAssignDisabled(false);
    }
  };

  useEffect(() => {
    if (doctor.saved) {
      dispatch(
        fetchSelectedDoctorSpecial({
          id: doctor.employeeId,
          office: doctor.office,
          base,
          startDate: monthRangeValue?.value ?? startDate,
          reason: doctor.reason,
        })
      );
    }
  }, [doctor.saved]);

  const onSelectFocus = (date, day) => {
    setSelectedDate(date);
    setDayOfWeek(day);
  };

  const filterOptionsByWeek = (options, weekIdx, invert) => {
    const week = doctor?.weeks?.[weekIdx];
    const firstDateOfWeek = head<any>(week)?.date;
    const lastDateOfWeek = last<any>(week)?.date;
    const todayDate = find(week, (o) => getIsToday(o?.date))?.date;
    const firstDateOfMonth = find(week, (o) => getIsFirstDateOfMonth(o?.date))?.date;
    const firstDateOfCurrentMonth = getIsSameMonth(firstDateOfMonth, monthRangeValue?.value)
      ? firstDateOfMonth
      : undefined;
    const lastDateOfCurrentMonth =
      !isNil(firstDateOfMonth) && !getIsSameMonth(firstDateOfMonth, monthRangeValue?.value)
        ? substractOneDay(firstDateOfMonth)
        : undefined;
    const startWeekDate = last(sortBy(filter([firstDateOfWeek, todayDate, firstDateOfCurrentMonth], Boolean)));
    const endWeekDate = head(sortBy(filter([lastDateOfWeek, lastDateOfCurrentMonth], Boolean)));

    return Object.entries(options ?? {})
      ?.filter(([date, _]) =>
        invert
          ? !getIsBetweenDates(startWeekDate, endWeekDate, date)
          : getIsBetweenDates(startWeekDate, endWeekDate, date)
      )
      ?.reduce((acc, [date, option]) => ({ ...acc, [date]: option }), {});
  };

  const filterEmptyOptions = (options) =>
    Object.entries(options ?? {})
      ?.filter(([_, option]) => !isEmpty(option))
      ?.reduce((acc, [date, option]) => ({ ...acc, [date]: option }), {});

  const filterNoSameMonthOptions = (options) =>
    Object.entries(options ?? {})
      ?.filter(([date, _]) => getIsSameMonth(date, monthRangeValue?.value))
      ?.reduce((acc, [date, option]) => ({ ...acc, [date]: option }), {});

  const onOfficeCheckboxChange = (weekIdx, checked) => {
    setIsWholeWeek((prev) => prev?.map((it, idx) => (isEqual(weekIdx, idx) ? checked : it)));
    setIsODWholeWeek((prev) => prev?.map((val, idx) => (isEqual(weekIdx, idx) ? false : val)));
    onCheckboxChange(weekIdx, checked, false);
  };

  const onODCheckboxChange = (weekIdx, checked) => {
    setIsODWholeWeek((prev) => prev?.map((it, idx) => (isEqual(weekIdx, idx) ? checked : it)));
    setIsWholeWeek((prev) => prev?.map((val, idx) => (isEqual(weekIdx, idx) ? false : val)));
    onCheckboxChange(weekIdx, checked, true);
  };

  const onCheckboxChange = (weekIdx, checked, odScheduleSelected) => {
    const filteredOptions = filterOptionsByWeek(selectedOptions, weekIdx, false);
    const invertedFilteredOptions = filterOptionsByWeek(selectedOptions, weekIdx, true);
    if (checked) {
      if (!isEmpty(filteredOptions)) {
        setSelectedOptions(() =>
          filterEmptyOptions({
            ...filter(
              filter(doctor?.weeks?.[weekIdx], (o) => getIsSameOrLaterThanToday(o?.date)),
              (o) => getIsSameMonth(monthRangeValue?.value, o?.date)
            )?.reduce((acc, { date, specialRangeDescription }, idx) => {
              return {
                ...acc,
                ...invertedFilteredOptions,
                [date]:
                  allWeekWithoutHOO() && !odScheduleSelected
                    ? Object.values(!isNil(filteredOptions) ? filteredOptions : {})[0]
                    : !isNil(filteredOptions)
                    ? getTemplateNameByOptions(
                        filteredOptions,
                        weekIdx,
                        idx,
                        specialRangeDescription,
                        odScheduleSelected
                      )
                    : {},
              };
            }, {}),
          })
        );
        setIsAssignDisabled(false);
      }
    }
  };

  const getCurrentMonthDate = (date) => {
    const isFirstDateOfMonth = getIsFirstDateOfMonth(date);

    if (isFirstDateOfMonth) return date;
    else {
      const week = head(doctor?.weeks);
      const firstDateOfWeek = find<any>(week, (o) => getIsMonday(o?.date))?.date;
      const firstDateOfMonth = find<any>(week, (o) => getIsFirstDateOfMonth(o?.date))?.date;
      const firstDateOfCurrentMonth = getIsSameMonth(monthRangeValue?.value, firstDateOfMonth)
        ? firstDateOfMonth
        : undefined;

      return last(filter(sortBy([firstDateOfWeek, firstDateOfCurrentMonth])));
    }
  };

  const onAssign = () => {
    const isDescriptionEntered = Object.entries(selectedOptions ?? {})
      .filter(([_, { value }]: any) => !!value)
      .some(([_, { description }]: any) => description.length < 1);

    if (isDescriptionEntered) {
      showErrorNotification('Please, provide a description');
      return;
    }

    setIsAssignDisabled(true);
    dispatch(
      assignTemplateSpecial({
        employeeId: doctor?.employeeId,
        office: doctor?.office,
        currentMonthDate: getCurrentMonthDate(monthRangeValue?.value),
        specialRangesTemplateDto: Object.entries(filterNoSameMonthOptions(selectedOptions) ?? {})
          .filter(([_, { value }]: any) => value !== null)
          .map(([date, { value, description, specialRangesReasons }]: any) => ({
            templateId: value,
            date,
            description,
            specialRangesReasons,
          })),
      })
    );
  };

  const onDrawerClose = () => {
    dispatch(setSelectedRow(null));
    setInitialDocTemplates(null);
    setSelectedOptions(null);
    setIsWholeWeek(new Array(6).fill(false));
    setIsODWholeWeek(new Array(6).fill(false));
    setIsAssignDisabled(true);
    setMonthRangeValue(head(monthRanges));
    onClose();
    dispatch(assignTemplateSpecialFailed(null));
    dispatch(setSelectedDoctor({}));
    dispatch(fetchDoctorsSpecial({ ...search, ...specialRangesSearch, ...specialRangesReasonsSearch }));
  };

  const onDescriptionChange = (e, date, weekIdx) => {
    setSelectedOptions(() => {
      return Object.fromEntries(
        Object.entries(selectedOptions ? selectedOptions : {}).map(([dateL, obj]: any) => {
          if (isWholeWeek?.[weekIdx] || isODWholeWeek?.[weekIdx]) {
            const weekDates = doctor?.weeks?.[weekIdx]?.map(({ date }) => date);

            if (weekDates?.includes(dateL)) {
              return [dateL, { ...obj, description: e.target.value }];
            } else {
              return [dateL, obj];
            }
          } else {
            if (isEqual(date, dateL)) {
              return [dateL, { ...obj, description: e.target.value }];
            } else {
              return [dateL, obj];
            }
          }
        })
      );
    });
    setIsAssignDisabled(false);
  };

  const descriptionOverlay = (date) => {
    return (
      <Menu>
        <Menu.Item key={date} className={styles['description-input-menu']}>
          {selectedOptions?.[date]?.description}
        </Menu.Item>
      </Menu>
    );
  };
  const range0TextOverlay = (text, date) => {
    return (
      <Menu selectable={false}>
        <Menu.Item key={date} className={styles['description-input-menu']}>
          {text}
        </Menu.Item>
      </Menu>
    );
  };
  const range0Column = (text, date, time) => {
    return (
      <Dropdown overlay={range0TextOverlay(text, date)} placement='bottomLeft'>
        <div
          className={
            isEmpty(time) || isSelectDisabled(date) ? 'grayed-out range0-template-text' : 'range0-template-text'
          }
        >
          {text}
        </div>
      </Dropdown>
    );
  };

  const descriptionColumn = (text, date, weekIdx) => {
    return (
      <Dropdown
        disabled={!selectedOptions?.[date]?.children || !getIsSameOrLaterThanToday(date)}
        overlay={descriptionOverlay(date)}
        placement='bottomLeft'
      >
        <Input
          size='small'
          style={selectedOptions?.[date]?.description?.length < 1 ? { border: '1px solid red' } : {}}
          maxLength={255}
          value={selectedOptions?.[date]?.description}
          onChange={(e) => onDescriptionChange(e, date, weekIdx)}
          className={styles['description-input']}
        />
      </Dropdown>
    );
  };

  const isSelectDisabled = (date) => {
    const isNoSameMonth = !getIsSameMonth(monthRangeValue?.value ?? startDate, date);
    const isEarlierThanToday = !getIsSameOrLaterThanToday(date);

    return some([isNoSameMonth, isEarlierThanToday], Boolean);
  };

  return (
    <>
      <Drawer
        placement='right'
        visible={isVisible}
        width={1050}
        closeIcon={<img src={'./icons/close-blue.svg'} alt='close-icon' />}
        className='pace-drawer-panel'
        title='Employee Special Ranges'
        forceRender={true}
        closable={true}
        onClose={onDrawerClose}
      >
        <div className='subheader'>
          <div className='subheader__text-date-wrap'>
            <div className='subheader__text'>
              <div className='subheader__name'>{doctor?.name}</div>
              <div className='subheader__id'>{doctor?.employeeId}</div>
              <div className='subheader__office'>{doctor?.office}</div>
              <div className='subheader__office-name'>{doctor?.officeName}</div>
            </div>
          </div>
          <StyledMonthSelect
            options={monthRangeValues}
            suffixIcon={<StyledCalendar src={'./icons/calendar.svg'} alt='calendar' />}
            value={monthRangeValue}
            onChange={(key, option) => setMonthRangeValue(option)}
            dropdownStyle={stylesMonthSelect}
          />
        </div>
        <Spin spinning={doctorLoading}>
          <div className='week'>
            {doctor?.weeks?.every((it) => isNil(it)) && <Empty />}
            {!isEmpty(doctor) &&
              !isNil(doctor?.weeks) &&
              doctor.weeks.map((week, weekIdx) => (
                <div key={weekIdx}>
                  {!isNil(week) && !isEmpty(week) && (
                    <div>
                      <div className='week__pattern-wrapper'>
                        <div />
                        <div>
                          <Text strong>Office HOO</Text>
                          <HourPatternCell
                            pattern={{
                              hours: week.map(({ office }) => (office ? office.duration : 0)),
                              averages: undefined,
                            }}
                          />
                        </div>
                      </div>
                      <Table
                        className='week-grid'
                        scroll={{ x: 450 }}
                        loading={loading}
                        dataSource={week?.map((it) => ({
                          key: `${it?.dayOfWeek}`,
                          template: 'select',
                          day: it?.dayOfWeek,
                          date: it?.date,
                          time: {
                            start: it?.personal?.start,
                            end: it?.personal?.end,
                          },
                          ooh: { start: it?.office?.start, end: it?.office?.end },
                          templateName: it?.templateName,
                          office: it?.office,
                        }))}
                        columns={[
                          {
                            title: (
                              <OfficeHeadCell
                                office={doctor?.office}
                                officeName={doctor?.officeName}
                                isSpecialRanges={true}
                              />
                            ),
                            dataIndex: 'template',
                            key: 'template',
                            width: 215,
                            render: (text, { day, date, ooh, time }) => {
                              const fitSchedule = () => {
                                return getIsFitSchedule({
                                  template: selectedOptions?.[date]?.schedule,
                                  office: ooh,
                                });
                              };
                              const dontHaveTemplateForParticularHOO = () => {
                                return getDontHaveTemplateForParticularHOO({
                                  template: selectedOptions?.[date],
                                  office: isWholeWeek[weekIdx] ? ooh : time,
                                });
                              };
                              const computeTitle = () => {
                                if (
                                  (selectedOptions?.[date]?.cantFindTemplate &&
                                    !isWholeWeek[weekIdx] &&
                                    !isODWholeWeek[weekIdx]) ||
                                  ((isWholeWeek[weekIdx] || isODWholeWeek[weekIdx]) &&
                                    dontHaveTemplateForParticularHOO().needShowWarning)
                                ) {
                                  return 'Template for\n this HOO not found';
                                } else if (
                                  selectedOptions?.[date]?.removedNL &&
                                  !isWholeWeek[weekIdx] &&
                                  !isODWholeWeek[weekIdx]
                                ) {
                                  return 'NL was suggested to remove';
                                } else if (fitSchedule().needShowWarning) {
                                  return 'Template outside\n of HOO';
                                }
                              };
                              return (
                                <SelectWrap>
                                  {!isSelectDisabled(date) &&
                                  (fitSchedule().needShowWarning ||
                                    ((isWholeWeek[weekIdx] || isODWholeWeek[weekIdx]) &&
                                      dontHaveTemplateForParticularHOO().needShowWarning) ||
                                    selectedOptions?.[date]?.cantFindTemplate ||
                                    selectedOptions?.[date]?.removedNL) ? (
                                    <StyledTooltip
                                      title={computeTitle()}
                                      color='white'
                                      overlayInnerStyle={tooltipInnerStyle}
                                      overlayStyle={tooltipStyle}
                                      placement='left'
                                      autoAdjustOverflow={false}
                                    >
                                      <img alt='info' src={'./icons/info-gray.svg'} />
                                    </StyledTooltip>
                                  ) : (
                                    <div style={{ width: 15 }} />
                                  )}
                                  <StyledSelect
                                    showSearch
                                    allowClear={getDayIndex(day) === dayOfWeek}
                                    placeholder='Select a template'
                                    size='small'
                                    onFocus={() => onSelectFocus(date, getDayIndex(day))}
                                    onBlur={() => onSelectBlur()}
                                    loading={
                                      !!templatesLoading &&
                                      templatesLoading.dayOfWeek === getDayIndex(day) &&
                                      selectedDate === date
                                    }
                                    onChange={(value, option) => onSelectChoice(value, option, weekIdx)}
                                    onSearch={onSelectSearch}
                                    onPopupScroll={(event) => onTemplatesScroll(event, day)}
                                    filterOption={() => true}
                                    value={!isEmpty(selectedOptions) ? selectedOptions?.[date]?.children : undefined}
                                    $isBold={
                                      !isEmpty(selectedOptions) ? !selectedOptions?.[date]?.suggested : undefined
                                    }
                                    getPopupContainer={() => document.querySelector('.ant-drawer-body .week')}
                                    disabled={isSelectDisabled(date)}
                                  >
                                    {templates[getDayIndex(day)] &&
                                      templates[getDayIndex(day)].map((it) => {
                                        return (
                                          <Select.Option
                                            value={it.id}
                                            key={it.id}
                                            schedule={{ start: it?.start, end: it?.end }}
                                          >
                                            {it.templateName}
                                          </Select.Option>
                                        );
                                      })}
                                  </StyledSelect>
                                </SelectWrap>
                              );
                            },
                          },
                          {
                            title: 'Description',
                            dataIndex: 'templateDescription',
                            key: 'templateDescription',
                            width: 200,
                            render: (text, { date }) => descriptionColumn(text, date, weekIdx),
                          },
                          {
                            title: 'Range 0 template',
                            dataIndex: 'templateName',
                            key: 'templateName',
                            render: (text, { time, date }) => range0Column(text, date, time),
                          },
                          {
                            title: 'Day',
                            dataIndex: 'day',
                            key: 'day',
                            width: 80,
                            render: (text, { time, date }) => (
                              <div className={isEmpty(time) || isSelectDisabled(date) ? 'grayed-out' : ''}>{text}</div>
                            ),
                          },
                          {
                            title: 'Date',
                            dataIndex: 'date',
                            key: 'date',
                            width: 80,
                            render: (text, { time, date }) => (
                              <div className={isEmpty(time) || isSelectDisabled(date) ? 'grayed-out' : ''}>
                                {formatDate(text)}
                              </div>
                            ),
                          },
                          {
                            title: 'Office HOO',
                            dataIndex: 'ooh',
                            key: 'ooh',
                            width: 130,
                            render: ({ start, end }, { date }) => (
                              <div className={isSelectDisabled(date) ? 'grayed-out' : ''}>{joinTime(start, end)}</div>
                            ),
                          },
                          {
                            title: 'OD Schedule',
                            dataIndex: 'time',
                            key: 'time',
                            width: 130,
                            render: ({ start, end }, { date }) => (
                              <div className={isSelectDisabled(date) ? 'grayed-out' : ''}>{joinTime(start, end)}</div>
                            ),
                          },
                        ]}
                        pagination={false}
                      />
                      <div className='week__assign-btn-wrap'>
                        <Checkbox
                          className='week__assign-checkbox'
                          onChange={(e) => {
                            setIsAssignDisabled(false);
                            onOfficeCheckboxChange(weekIdx, e.target.checked);
                          }}
                          checked={isWholeWeek[weekIdx]}
                          disabled={isDisabled(weekIdx)}
                        >
                          Same template to whole week
                        </Checkbox>
                        <Checkbox
                          className='week__assign-checkbox'
                          onChange={(e) => onODCheckboxChange(weekIdx, e.target.checked)}
                          checked={isODWholeWeek[weekIdx]}
                          disabled={isDisabled(weekIdx)}
                        >
                          Apply template by OD Schedule
                        </Checkbox>
                        <Button
                          disabled={
                            isAssignDisabled && !week?.map((e) => e.suggestedTemplate).some((el) => el !== null)
                          }
                          //disabled={isAssignDisabled || isEqual(selectedOptions, initialDocTemplates)}
                          className='week__assign-btn'
                          type='primary'
                          onClick={onAssign}
                        >
                          Assign
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </Spin>
      </Drawer>
    </>
  );
}

export default SpecialRangesDetails;

const SelectWrap = styled.div`
  display: flex;
`;

const tooltipInnerStyle = {
  color: 'black',
  fontSize: 10,
  whiteSpace: 'pre-wrap',
  lineHeight: 1.2,
  padding: 2,
  display: 'flex',
  alignItems: 'center',
};

const tooltipStyle = {
  padding: '0 10 0 0',
};

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
  width: 15px;
  padding-left: 1px;
  padding-right: 1px;
`;
