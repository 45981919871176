/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Collapse, DatePicker, Drawer, Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createException } from './actions';
import ExpandIcon from '../common/ExpandIcon';
import { ExceptionCategoryEnum } from '../../models/exception-catergory-enum';
import './Styles.module.scss';
import moment from 'moment';
import Input from 'antd/lib/input/Input';
import { getEmployees, getOffices } from './selectors';
import TextArea from 'antd/lib/input/TextArea';

interface IExceptionDetailsProps {
  id: any;
  excepId?: string;
  record: any;
  setSelectedException: any;
  hideDetails?: any;
  setExceptionDrawer: any;
  exceptionDrawer: any;
}

function ExceptionDetails({
  id,
  excepId,
  record,
  setRowDetail,
  setSelectedException,
  hideDetails,
  setExceptionDrawer,
  exceptionDrawer,
}: IExceptionDetailsProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const name = 'exception-details';
  const classNameForm = `${name}-form`;
  const { Panel } = Collapse;

  const [office, setOffice]: any = useState(null);
  const [employee, setEmployee]: any = useState(null);
  const [isDisabled, setIsDisabled]: any = useState(true);
  const [effectiveDate, setEffectiveDate] = useState<any>('');
  const [exceptionCategory, setExceptionCategory] = useState([]);
  const [endDate, setEndDate] = useState<any>('');
  const disCheck = !isDisabled ? false : true;
  const employees = useSelector(getEmployees);
  const offices = useSelector(getOffices);
  useEffect(() => {
    if (excepId) {
      const category = Object.entries(ExceptionCategoryEnum)?.filter((item) => item[0] === record?.exceptionCategory);
      const details = {
        office: `${record?.officeName} (${record?.officeNumber})`,
        employee: `${record?.employeeName} (${record?.employeeID})`,
        exceptionCategory: category[0][1],
        effectiveDate: record?.effectiveDate ? moment(record?.effectiveDate) : '',
        endDate: record?.endDate ? moment(record?.endDate) : '',
        notes: record?.notes,
      };
      form.setFieldsValue(details);
      setEffectiveDate(record?.effectiveDate);
      setEndDate(record?.endDate);
      setExceptionCategory(record?.exceptionCategory);
      setEmployee({ value: `${record?.employeeName} (${record?.employeeID})`, key: record?.employeeID });
      setOffice({ value: `${record?.officeName} (${record?.officeNumber})`, key: record?.officeNumber });
    }
  }, [excepId]);

  const onSubmit = (formData) => {
    const { effectiveDate, endDate, notes } = formData;
    let payload = {
      ...(effectiveDate && form.isFieldTouched('effectiveDate') && { effectiveDate }),
      ...(endDate && form.isFieldTouched('endDate') && { endDate }),
      ...(exceptionCategory && form.isFieldTouched('exceptionCategory') && { exceptionCategory }),
      ...(notes && form.isFieldTouched('notes') && { notes }),
      officeName: office?.value?.split(' ')[0].replace(/[{()}]/g, ''),
      officeNumber: office?.key,
      employeeName:
        employee?.value?.split(' ')[0].replace(/[{()}]/g, '') +
        ' ' +
        employee?.value?.split(' ')[1].replace(/[{()}]/g, ''),
      employeeID: employee?.key,
      exceptionCategory: exceptionCategory,
    };
    if (excepId) {
      payload = {
        exceptionId: record?.exceptionId,
        ...payload,
      };
    }
    dispatch(createException(payload));
    setIsDisabled(true);
    form.resetFields();
    hideDetails();
  };
  return (
    <Drawer
      placement='right'
      visible={exceptionDrawer}
      width={335}
      closable={false}
      className='drawer-panel'
      forceRender={true}
      onClose={() => {
        setExceptionDrawer(false);
        form.resetFields();
        setSelectedException(null);
        setRowDetail({});
      }}
    >
      <div className='details-panel'>
        <div className='details-panel-header'>
          {excepId ? <span>UPDATE EXCEPTION</span> : <span>ADD NEW EXCEPTION</span>}
          <Button
            type={'ghost'}
            className='details-panel-btn'
            onClick={() => {
              hideDetails();
              form.resetFields();
            }}
          >
            CLOSE ALL &#10006;
          </Button>
        </div>
        <Collapse
          destroyInactivePanel
          defaultActiveKey={1}
          bordered={true}
          expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
          className='med-custom-collapse'
          ghost={true}
        >
          <Panel header='EXCEPTION MANAGEMENT' key={1} className='med-custom-panel'>
            <Form
              form={form}
              name={name}
              layout='vertical'
              onFinish={onSubmit}
              autoComplete='off'
              className={classNameForm}
              onValuesChange={() => setIsDisabled(false)}
            >
              <Form.Item
                key='employee'
                label={'Employee Name and ID'}
                name='employee'
                rules={[{ required: true, message: 'Please select Employee' }]}
                className='label-text region-field'
              >
                <Select
                  allowClear
                  maxTagCount={'responsive'}
                  // placeholder='...'
                  disabled={moment(record?.endDate)?.isBefore(moment()) || !!excepId}
                  showSearch
                  options={employees?.map(({ employeeNum, employeeName }) => {
                    return {
                      employeeNum,
                      value: `${employeeName} (${employeeNum})`,
                      key: employeeNum,
                    };
                  })}
                  filterOption={(inputValue, option: any) => {
                    const item = option.value.split('(');
                    return (
                      get(item, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
                      get(item, '[1]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option: [] | any) => {
                    if (!option) {
                      setEmployee([]);
                      form.resetFields(['employee']);
                    } else {
                      setEmployee(option);
                      form.setFieldValue('employee', value);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                key='office'
                label={'Office Name and Number'}
                name='office'
                className='label-text region-field'
                rules={[{ required: true, message: 'Please select Office' }]}
              >
                <Select
                  allowClear
                  maxTagCount={'responsive'}
                  placeholder='...'
                  showSearch
                  disabled={moment(record?.endDate)?.isBefore(moment()) || !!excepId}
                  options={offices?.map(({ locationNumber, locationName }) => {
                    return {
                      locationNumber,
                      value: `${locationName} (${locationNumber})`,
                      key: locationNumber,
                    };
                  })}
                  filterOption={(inputValue, option: any) => {
                    const item = option.value.split('(');
                    return (
                      get(item, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
                      get(item, '[1]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option: [] | any) => {
                    if (!option) {
                      setOffice([]);
                      form.resetFields(['office']);
                    } else {
                      setOffice(option);
                      form.setFieldValue('office', value);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                key='exceptionCategory'
                label={'Exception Category'}
                name='exceptionCategory'
                className='label-text region-field'
                rules={[{ required: true, message: 'Please select Exception Category' }]}
              >
                <Select
                  allowClear
                  maxTagCount={'responsive'}
                  showSearch
                  disabled={record?.endDate ? moment(record?.endDate)?.isBefore(moment()) : false}
                  options={Object.entries(ExceptionCategoryEnum)?.map((item) => {
                    return {
                      item,
                      value: `${item[1]}`,
                      key: item[0],
                    };
                  })}
                  filterOption={(inputValue, option: any) => {
                    const item = option.value.split('(');
                    return get(item, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                  }}
                  onChange={(value, option: [] | any) => {
                    if (!option) {
                      setExceptionCategory([]);
                      form.resetFields(['exceptionCategory']);
                    } else {
                      setExceptionCategory(option.key);
                      form.setFieldValue('exceptionCategory', value);
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                key='effectiveDate'
                label={'Effective Date'}
                name='effectiveDate'
                className='label-text region-field'
                rules={[{ required: true, message: 'Please select Effective Date' }]}
              >
                <DatePicker
                  disabled={moment(record?.endDate)?.isBefore(moment())}
                  value={effectiveDate && moment(effectiveDate)}
                  disabledDate={(d) => moment(endDate)?.isBefore(d)}
                  placeholder='Effective Date'
                  onChange={(value) => setEffectiveDate(value)}
                />
              </Form.Item>
              <Form.Item key='endDate' label={'End Date'} name='endDate' className='label-text region-field'>
                <DatePicker
                  disabled={moment(record?.endDate)?.isBefore(moment())}
                  value={endDate && moment(endDate)}
                  disabledDate={(d) => moment(effectiveDate)?.isAfter(d)}
                  placeholder='End Date'
                  onChange={(value) => setEndDate(value)}
                />
              </Form.Item>
              <Form.Item key='notes' label={'Notes'} name='notes' className='label-text region-field'>
                <TextArea disabled={moment(record?.endDate)?.isBefore(moment())} />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' disabled={disCheck} className={'btn-form-action'}>
                  SAVE
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </div>
    </Drawer>
  );
}

export default ExceptionDetails;
